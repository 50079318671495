<template>
  <div>
    <custom-table
      ref="customTable"
      :columns="columns"
      :custom-view="customView"
      :footer-actions="footerActions"
      :sums="sums"
      api-url="/order-list"
      api-download-url="/order-export"
      download-file-name="pos-don-hang.xlsx"
      @showDetailDialog="showDetailDialog"
    ></custom-table>

    <v-dialog v-model="detailDialog" max-width="1200px">
      <OrderDetail
        v-if="detailDialog"
        :order="selectedOrder"
        @close="hideDetailDialog"
      />
    </v-dialog>
  </div>
</template>

<script>
import { CUSTOM_VIEW_POS_ORDER } from "@/libs/const";
import moment from "moment/moment";

export default {
  name: "OrderList",
  components: {
    OrderDetail: () => import("@/components/pos/OrderDetail"),
  },
  data: () => ({
    customView: CUSTOM_VIEW_POS_ORDER,
    columns: [],
    footerActions: [],
    sums: [],
    isLoading: false,
    viewOption: "view0",
    selectedOrder: {},
    detailDialog: false,
  }),
  created() {
    const columns = [
      {
        type: "date-range-filter",
        label: this.$t("labels.create_time_1"),
        placeholder: this.$t("labels.create_time_1"),
        name: "created_at",
        hasSort: false,
        sortName: "created_at",
        key: "created_at",
        required: true,
        defaultValue: [
          moment().format("YYYY-MM-DD"),
          moment().format("YYYY-MM-DD"),
        ],
      },
      /* {
                type: 'select-pos',
                label: this.$t('labels.pos'),
                placeholder: this.$t('labels.pos'),
                name: 'id_pos',
                hasSort: false,
                sortName: 'pos_code',
                key: 'pos_code',
                required: true,
            }, */
      {
        type: "input-filter",
        label: this.$t("labels.tracking"),
        placeholder: this.$t("labels.tracking"),
        name: "tracking_id",
        hasSort: false,
        sortName: "tracking_id",
        key: "tracking_id",
        required: true,
        action: "showDetailDialog",
        actionText: this.$t("labels.update"),
        actionPermissions: [],
      },
      {
        type: "input-filter",
        label: this.$t("labels.employee_create"),
        placeholder: this.$t("labels.employee_create"),
        name: "employee_name",
        hasSort: false,
        sortName: "employee_name",
        key: "employee_name",
        required: true,
      },
      {
        type: "input-filter",
        label: this.$t("labels.employee_care"),
        placeholder: this.$t("labels.employee_care"),
        name: "employee_care_name",
        hasSort: false,
        sortName: "employee_care_name",
        key: "employee_care_name",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.base_price"),
        placeholder: this.$t("labels.base_price"),
        name: "base_price",
        hasSort: false,
        sortName: "base_price",
        key: "base_price",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.product_promotion"),
        placeholder: this.$t("labels.product_promotion"),
        name: "product_promotion",
        hasSort: false,
        sortName: "product_promotion",
        key: "product_promotion",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.exchange_payment_price"),
        placeholder: this.$t("labels.exchange_payment_price"),
        name: "exchange_payment_price",
        hasSort: false,
        sortName: "exchange_payment_price",
        key: "exchange_payment_price",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.bill_price"),
        placeholder: this.$t("labels.bill_price"),
        name: "bill_price",
        hasSort: false,
        sortName: "bill_price",
        key: "bill_price",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.bill_promotion"),
        placeholder: this.$t("labels.bill_promotion"),
        name: "bill_promotion",
        hasSort: false,
        sortName: "bill_promotion",
        key: "bill_promotion",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.voucher_promotion"),
        placeholder: this.$t("labels.voucher_promotion"),
        name: "voucher_promotion",
        hasSort: false,
        sortName: "voucher_promotion",
        key: "voucher_promotion",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.payment_price"),
        placeholder: this.$t("labels.payment_price"),
        name: "payment_price",
        hasSort: false,
        sortName: "payment_price",
        key: "payment_price",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.payment_transfer"),
        placeholder: this.$t("labels.payment_transfer"),
        name: "payment_transfer",
        hasSort: false,
        sortName: "payment_transfer",
        key: "payment_transfer",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.payment_cash"),
        placeholder: this.$t("labels.payment_cash"),
        name: "payment_cash",
        hasSort: false,
        sortName: "payment_cash",
        key: "payment_cash",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.payment_card"),
        placeholder: this.$t("labels.payment_card"),
        name: "payment_card",
        hasSort: false,
        sortName: "payment_card",
        key: "payment_card",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.payment_point"),
        placeholder: this.$t("labels.payment_point"),
        name: "payment_point",
        hasSort: false,
        sortName: "payment_point",
        key: "payment_point",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.payment_return"),
        placeholder: this.$t("labels.payment_return"),
        name: "payment_return",
        hasSort: false,
        sortName: "payment_return",
        key: "payment_return",
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.final_revenue"),
        placeholder: this.$t("labels.final_revenue"),
        name: "final_revenue",
        hasSort: false,
        sortName: "final_revenue",
        key: "final_revenue",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.funding_money"),
        placeholder: this.$t("labels.funding_money"),
        name: "funding_money",
        hasSort: false,
        sortName: "funding_money",
        key: "funding_money",
        required: true,
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.total_cost"),
        placeholder: this.$t("labels.total_cost"),
        name: "total_cost",
        hasSort: false,
        sortName: "total_cost",
        key: "total_cost",
        permissions: ["super_admin"],
      },
      {
        type: "input-filter-from-to",
        label: this.$t("labels.gross_profit"),
        placeholder: this.$t("labels.gross_profit"),
        name: "gross_profit",
        hasSort: false,
        sortName: "gross_profit",
        key: "gross_profit",
        permissions: ["super_admin"],
      },
    ];
    this.columns = [...columns];

    const sums = [
      {
        text: this.$t("labels.order"),
        key: "total",
      },
      {
        text: this.$t("labels.funding_money"),
        key: "total_funding_money",
      },
      {
        text: this.$t("labels.payment_price"),
        key: "total_payment_price",
      },
      {
        text: this.$t("labels.payment_transfer"),
        key: "total_payment_transfer",
      },
      {
        text: this.$t("labels.final_revenue"),
        key: "total_final_revenue",
      },
    ];
    this.sums = [...sums];
  },
  methods: {
    showDetailDialog(item) {
      this.selectedOrder = { ...item };
      this.detailDialog = true;
    },
    hideDetailDialog() {
      this.selectedOrder = {};
      this.detailDialog = false;
    },
  },
};
</script>

<style scoped></style>
